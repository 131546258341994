export const DownloadIcon = () => {
  return (
    <svg
      version="1.1"
      viewBox="0 0 24 16"
      width="24px"
      style={{ marginLeft: "-5px" }}
    >
      <title />
      <desc />
      <defs />
      <g
        fill="none"
        fill-rule="evenodd"
        id="Page-1"
        stroke="none"
        stroke-width="1"
      >
        <g
          fill="#000000"
          id="Core"
          transform="translate(-504.000000, -88.000000)"
        >
          <g id="cloud-download" transform="translate(504.000000, 88.000000)">
            <path
              d="M19.4,6 C18.7,2.6 15.7,0 12,0 C9.1,0 6.6,1.6 5.4,4 C2.3,4.4 0,6.9 0,10 C0,13.3 2.7,16 6,16 L19,16 C21.8,16 24,13.8 24,11 C24,8.4 21.9,6.2 19.4,6 L19.4,6 Z M17,9 L12,14 L7,9 L10,9 L10,5 L14,5 L14,9 L17,9 L17,9 Z"
              id="Shape"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
export const ResetIcon = () => {
  return (
    <svg
      style={{
        fillRule: "evenodd",
        clipRule: "evenodd",
        strokeLinejoin: "round",
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox="0 0 30 30"
      xmlSpace="preserve"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsSerif="http://www.serif.com/"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path d="M16.08,2c-7.726,0 -14,6.273 -14,14c0,7.727 6.274,14 14,14c7.727,0 14,-6.273 14,-14c0,-7.727 -6.273,-14 -14,-14Zm4.283,7.609c2.238,1.385 3.728,3.822 3.728,6.6c0,4.317 -3.595,7.81 -8.011,7.81c-4.415,-0 -8.011,-3.493 -8.011,-7.81c0,-3.473 2.331,-6.415 5.54,-7.43c0.527,-0.167 0.819,-0.729 0.652,-1.255c-0.166,-0.527 -0.729,-0.819 -1.255,-0.652c-4.027,1.274 -6.937,4.978 -6.937,9.337c0,5.408 4.48,9.81 10.011,9.81c5.532,-0 10.011,-4.402 10.011,-9.81c0,-3.356 -1.724,-6.324 -4.355,-8.093l0.5,-0.128c0.535,-0.137 0.858,-0.682 0.721,-1.217c-0.136,-0.534 -0.681,-0.858 -1.216,-0.721l-3.165,0.809c-0.261,0.067 -0.484,0.236 -0.619,0.469c-0.134,0.233 -0.169,0.511 -0.096,0.77l0.848,3.019c0.149,0.532 0.702,0.842 1.233,0.692c0.531,-0.149 0.842,-0.701 0.692,-1.233l-0.271,-0.967Z" />
    </svg>
  );
};
export const CheckboxIcon = () => {
  return (
    <svg height="19px" version="1.1" viewBox="0 0 20 20" width="19px">
      <title />
      <desc />
      <defs />
      <g
        fill="none"
        fill-rule="evenodd"
        id="Page-1"
        stroke="none"
        stroke-width="1"
      >
        <g
          fill="#000000"
          id="Core"
          transform="translate(-44.000000, -86.000000)"
        >
          <g id="check-circle" transform="translate(44.000000, 86.000000)">
            <path
              d="M10,0 C4.5,0 0,4.5 0,10 C0,15.5 4.5,20 10,20 C15.5,20 20,15.5 20,10 C20,4.5 15.5,0 10,0 L10,0 Z M8,15 L3,10 L4.4,8.6 L8,12.2 L15.6,4.6 L17,6 L8,15 L8,15 Z"
              id="Shape"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
export const TextIcon = () => {
  return (
    <svg id="Layer_3" version="1.1" viewBox="0 0 50 50">
      <g>
        <polygon points="8,9 10,9 10,12 10,16 10,39 8,39 8,42 16,42 16,39 14,39 14,16 14,12 14,9 16,9 16,6 8,6  " />
        <polygon points="4,16 7.021,16 7.021,12 0,12 0,36 7.042,36 7.042,32 4,32  " />
        <polygon points="16.979,12 16.979,16 44,16 44,32 16.958,32 16.958,36 48,36 48,12  " />
      </g>
    </svg>
  );
};
export const TitleIcon = () => {
  return (
    <svg id="Layer_3" version="1.1" viewBox="0 0 50 50">
      <g>
        <polygon points="16,33 14,33 14,10 14,6 14,3 16,3 16,0 8,0 8,3 10,3 10,6 10,10 10,33 8,33 8,36 16,36  " />
        <polygon points="17.031,6 17.031,10 44,10 44,44 4,44 4,10 6.938,10 6.938,6 0,6 0,48 48,48 48,6  " />
      </g>
    </svg>
  );
};
export const CompanyIcon = () => {
  return (
    <svg viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
      <title />
      <g data-name="Layer 45" id="Layer_45">
        <rect height="2" width="1" x="46" y="16" />
        <rect height="2" width="1" x="52" y="37" />
        <rect height="2" width="1" x="46" y="30" />
        <rect height="2" width="1" x="40" y="23" />
        <rect height="2" width="1" x="40" y="16" />
        <rect height="2" width="1" x="46" y="23" />
        <rect height="2" width="1" x="40" y="37" />
        <rect height="2" width="1" x="46" y="37" />
        <rect height="2" width="1" x="52" y="16" />
        <rect height="2" width="1" x="52" y="30" />
        <rect height="2" width="1" x="40" y="30" />
        <rect height="2" width="1" x="52" y="23" />
        <path d="M59,57V55H58V11H48V9h8V3H46v8H35V29H33a11,11,0,0,0-21.9,0H6V55H5v2H3v4H61V57ZM48,5h6V7H48ZM22,22a2,2,0,1,1-2,2A2,2,0,0,1,22,22ZM32,53H10V47H32Zm0-7H10V40H32Zm0-7H10V33H32ZM53,55H41V50.5A5.51,5.51,0,0,1,46.5,45h1A5.51,5.51,0,0,1,53,50.5ZM50,14h5v6H50Zm0,7h5v6H50Zm0,7h5v6H50Zm0,7h5v6H50ZM44,14h5v6H44Zm0,7h5v6H44Zm0,7h5v6H44Zm0,7h5v6H44ZM38,14h5v6H38Zm0,7h5v6H38Zm0,7h5v6H38Zm0,7h5v6H38Z" />
      </g>
    </svg>
  );
};
export const EmailIcon = () => {
  return (
    <svg
      width={"20px"}
      height={"19px"}
      viewBox="0 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M256 352c-16.53 0-33.06-5.422-47.16-16.41L0 173.2V400C0 426.5 21.49 448 48 448h416c26.51 0 48-21.49 48-48V173.2l-208.8 162.5C289.1 346.6 272.5 352 256 352zM16.29 145.3l212.2 165.1c16.19 12.6 38.87 12.6 55.06 0l212.2-165.1C505.1 137.3 512 125 512 112C512 85.49 490.5 64 464 64h-416C21.49 64 0 85.49 0 112C0 125 6.01 137.3 16.29 145.3z" />
    </svg>
  );
};
export const NameIcon = () => {
  return (
    <svg
      enable-background="new 0 0 48 48"
      id="Layer_1"
      version="1.1"
      viewBox="0 0 48 48"
      style={{ marginTop: "-1px" }}
    >
      <g>
        <g>
          <circle cx="24" cy="18.796" fill="#231F20" r="5.084" />
          <path
            d="M30.943,25.688H17.056c-0.904,0-1.638,0.733-1.638,1.638v10.337c2.501,1.554,5.443,2.467,8.605,2.467    c3.142,0,6.066-0.902,8.559-2.439V27.326C32.582,26.422,31.848,25.688,30.943,25.688z"
            fill="#231F20"
          />
        </g>
        <path
          d="M24,0C10.745,0,0,10.745,0,24s10.745,24,24,24s24-10.745,24-24S37.255,0,24,0z M24.029,43.844   c-11.023,0-19.959-8.936-19.959-19.959S13.006,3.926,24.029,3.926s19.959,8.936,19.959,19.959S35.052,43.844,24.029,43.844z"
          fill="#231F20"
        />
      </g>
    </svg>
  );
};
export const FirstLastNameIcon = () => {
  return (
    <svg
      style={{ marginTop: "-1px" }}
      enable-background="new 0 0 48 48"
      height="30px"
      id="Layer_1"
      version="1.1"
      viewBox="0 0 68 68"
      width="30px"
    >
      <path
        d="M24,0C10.746,0,0,10.745,0,24s10.746,24,24,24c13.256,0,24-10.745,24-24S37.256,0,24,0z M24,13.713  c2.809,0,5.084,2.275,5.084,5.083c0,2.809-2.275,5.084-5.084,5.084c-2.807,0-5.083-2.275-5.083-5.084  C18.917,15.988,21.193,13.713,24,13.713z M32.582,37.69c-2.491,1.537-5.416,2.439-8.559,2.439c-3.161,0-6.104-0.913-8.604-2.467  V27.326c0-0.904,0.733-1.638,1.638-1.638h13.888c0.904,0,1.638,0.733,1.638,1.638V37.69z"
        fill="#231F20"
      />
    </svg>
  );
};
export const DateIcon = () => {
  return (
    <svg
      style={{ marginTop: "-2px" }}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M19 4h-2V2h-2v2H9V2H7v2H5c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2h14c1.103 0 2-.897 2-2V6c0-1.103-.897-2-2-2zm-1 15h-6v-6h6v6zm1-10H5V7h14v2z" />
    </svg>
  );
};
export const SignatureIcon = () => {
  return (
    <svg viewBox="0 0 576 512" xmlns="http://www.w3.org/2000/svg">
      <path d="M292.7 342.3C289.7 345.3 288 349.4 288 353.7V416h62.34c4.264 0 8.35-1.703 11.35-4.727l156.9-158l-67.88-67.88L292.7 342.3zM568.5 167.4L536.6 135.5c-9.875-10-26-10-36 0l-27.25 27.25l67.88 67.88l27.25-27.25C578.5 193.4 578.5 177.3 568.5 167.4zM256 0v128h128L256 0zM256 448c-16.07-.2852-30.62-9.359-37.88-23.88c-2.875-5.875-8-6.5-10.12-6.5s-7.25 .625-10 6.125l-7.749 15.38C187.6 444.6 181.1 448 176 448H174.9c-6.5-.5-12-4.75-14-11L144 386.6L133.4 418.5C127.5 436.1 111 448 92.45 448H80C71.13 448 64 440.9 64 432S71.13 416 80 416h12.4c4.875 0 9.102-3.125 10.6-7.625l18.25-54.63C124.5 343.9 133.6 337.3 144 337.3s19.5 6.625 22.75 16.5l13.88 41.63c19.75-16.25 54.13-9.75 66 14.12C248.5 413.2 252.2 415.6 256 415.9V347c0-8.523 3.402-16.7 9.451-22.71L384 206.5V160H256c-17.67 0-32-14.33-32-32L224 0H48C21.49 0 0 21.49 0 48v416C0 490.5 21.49 512 48 512h288c26.51 0 48-21.49 48-48V448H256z" />
    </svg>
  );
};
export const IntialIcon = () => {
  return (
    <div style={{ display: "flex", textDecoration: "underline" }}>
      <div style={{ fontWeight: "bold" }}>D</div>
      <div style={{ fontWeight: "bold" }}>S</div>
    </div>
  );
};
export const StampIcon = () => {
  return (
    <svg
      style={{ marginTop: "-1px" }}
      viewBox="0 0 256 256"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect fill="none" height="256" width="256" />
      <path d="M224,224a8,8,0,0,1-8,8H40a8,8,0,0,1,0-16H216A8,8,0,0,1,224,224Zm0-80v40a16,16,0,0,1-16,16H48a16,16,0,0,1-16-16V144a16,16,0,0,1,16-16h54.1L86.4,54.7A32,32,0,0,1,117.7,16h20.6a32,32,0,0,1,31.3,38.7L153.9,128H208A16,16,0,0,1,224,144Z" />
    </svg>
  );
};
