import React, { useState } from "react";
import { ConfirmOrCancel } from "../Buttons/ConfirmOrCancel";
import { primary45 } from "../../utils/colors";
import { ContainerModal } from "./ContainerModal";
import "@fontsource/dancing-script"; // Dancing Script
import "@fontsource/pacifico"; // Pacifico
import "@fontsource/sacramento"; // Sacramento
import "@fontsource/allura"; // Allura
import "@fontsource/caveat"; // Caveat
import "@fontsource/satisfy"; // Satisfy
import "@fontsource/parisienne"; // Parisienne
import "@fontsource/over-the-rainbow"; // Over the Rainbow

export function DefaultSignature({
  onConfirm,
  onClose,
  autoDate,
  setAutoDate,
}) {
  const [name, setName] = useState("");
  const [isHovered, setIsHovered] = useState(null);
  const [selectedStyle, setSelectedStyle] = useState(null)

  const handleMouseEnter = (value) => {
    setIsHovered(value);
  };

  const handleMouseLeave = () => {
    setIsHovered(null);
  };

  const handleChange = (event) => {
    setName(event.target.value);
  };
  const styles = {
    stampContainer: {
      // display: "flex",
    },
    instructions: {
      display: "flex",
      justifyContent: "space-between",
      textAlign: "center",
      color: primary45,
      marginTop: 8,
      width: 600,
      alignSelf: "center",
    },
    instructionsContainer: {
      display: "flex",
      justifyContent: "center",
    },
    inputStyle: {
      height: "30px",
      width: "100%",
      border: "1px solid #F0F0F0",
      padding: '5px'
    },
    listStyle: {
      marginBottom: "20px",
      border: "1px solid #F0F0F0",
      overflow: "scroll",
      marginLeft: "1px",
      height: "200px",
    },
  };

  const className = [
    "dancing-script",
    "pacifico",
    "sacramento",
    "allura",
    "caveat",
    "satisfy",
    "parisienne",
    "over-the-rainbow",
  ];
  return (
    <ContainerModal
      isVisible={true}
      title="Add Stamp"
      onClose={onClose}
      body={
        <div style={styles.container}>
          <div style={styles.stampContainer}>
            <div>
              <input
                type="text"
                value={name}
                onChange={handleChange}
                style={styles?.inputStyle}
                placeholder="Type your name"
              />
            </div>
            <div className="signatures" style={styles?.listStyle}>
              {name &&
                className?.map((item, index) => (
                  <div
                    onMouseEnter={() => handleMouseEnter(index)}
                    onMouseLeave={handleMouseLeave}
                    key={index}
                    onClick={() => setSelectedStyle(item)}
                    className={`signature ${item}`}
                    style={{
                      padding: "5px",
                      background: (isHovered === index || (selectedStyle === item)) ? "#F0F0F0" : "",
                    }}
                  >
                    {name}
                  </div>
                ))}
            </div>
          </div>
          <ConfirmOrCancel
            onCancel={onClose}
            // disabled={!file}
            onConfirm={() => {
              onConfirm(selectedStyle, name);
            }}
          />
        </div>
      }
    />
  );
}
