import { primary45 } from "../../utils/colors";

export function Header() {
  const styles = {
    container: {
      backgroundColor: primary45,
      color: "#FFF",
      padding: 12,
      fontWeight: 600,
      position: "fixed",
      width: "100%",
      top: "0",
      zIndex: "1",
    },
  };
  return (
    <div style={styles.container}>
      <div>E-Signature Platform</div>
    </div>
  );
}
