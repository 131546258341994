import React from "react";
import { CustomButton } from "./CustomButton";

export function ConfirmOrCancel({
  onCancel,
  onConfirm,
  confirmTitle = "Confirm",
  leftBlock,
  hideCancel,
  disabled,
}) {
  const styles = {
    actions: {
      display: "flex",
      justifyContent: "space-between",
    },
    cancel: {
      marginRight: 8,
    },
  };

  return (
    <div style={styles.actions}>
      <div>{leftBlock}</div>
      <div style={{ display: "flex", gap: "5px" }}>
        {!hideCancel ? (
          <CustomButton
            title={"Cancel"}
            style={styles.cancel}
            onClick={onCancel}
            btn={true}
          />
        ) : null}
        <CustomButton
          title={confirmTitle}
          btn={true}
          inverted={true}
          onClick={onConfirm}
          disabled={disabled}
        />
      </div>
    </div>
  );
}
