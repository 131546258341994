import Draggable from "react-draggable";
import { FaCheck, FaTimes } from "react-icons/fa";
import { errorColor, goodColor, primary45 } from "../../utils/colors";
import imageUrl from "../../assets/image/checkbox.png";

export default function CheckboxDraggable({ url, onEnd, onSet, onCancel }) {
  const styles = {
    container: {
      position: "absolute",
      zIndex: 100000,
      marginTop: "100px",
      border: `2px solid ${primary45}`,
    },
    controls: {
      right: 0,
      backgroundColor: primary45,
    },
    smallButton: {
      display: "inline-block",
    },
  };
  return (
    <Draggable onStop={onEnd}>
      <div style={styles.container}>
        <div style={styles.controls}>
          <div style={styles.smallButton} onClick={onSet}>
            <FaCheck color={goodColor} />
          </div>
          <div style={styles.smallButton} onClick={onCancel}>
            <FaTimes color={errorColor} />
          </div>
        </div>
        <img
          src={imageUrl}
          alt="img"
          width={30}
          style={styles.img}
          draggable={false}
        />
      </div>
    </Draggable>
  );
}
