import "./App.css";
import { useRef, useState } from "react";
import Drop from "./components/Drop/Drop";
import { Document, Page, pdfjs } from "react-pdf";
import { PDFDocument, rgb, StandardFonts } from "pdf-lib";
import { blobToURL } from "./utils/Utils";
import { Header } from "./components/Header/Header";
import { NavTab } from "./components/Tabs/NavTab";
import ImageDraggable from "./components/Draggable/ImageDraggable";
import TextDraggable from "./components/Draggable/TextDraggable";
import dayjs from "dayjs";
import { StampModal } from "./components/Modals/StampModal";
import { SignatureModal } from "./components/Modals/SignatureModal";
import Pagination from "./components/Pagination/Pagination";
import imageCheck from "./assets/image/check.png";
import alluraRegular from "./assets/icon/Allura-Regular.ttf";
import caveatRegular from "./assets/icon/Caveat-Regular.ttf";
import danceScript from "./assets/icon/Dancing-Script.ttf";
import overtheRainbow from "./assets/icon/OvertheRainbow.ttf";
import pacifico from "./assets/icon/Pacifico.ttf";
import parisienneRegular from "./assets/icon/Parisienne-Regular.ttf";
import sacramentoRegular from "./assets/icon/Sacramento-Regular.ttf";
import satisfyRegular from "./assets/icon/Satisfy-Regular.ttf";
import robotRegular from "./assets/icon/Roboto-Regular.ttf";
import {
  CheckboxIcon,
  CompanyIcon,
  DateIcon,
  DownloadIcon,
  EmailIcon,
  FirstLastNameIcon,
  IntialIcon,
  NameIcon,
  ResetIcon,
  SignatureIcon,
  StampIcon,
  TextIcon,
  TitleIcon,
} from "./components/Icon";
import fontkit from "@pdf-lib/fontkit";

import { DefaultSignature } from "./components/Modals/DefaultSignature";
import CheckboxDraggable from "./components/Draggable/CheckboxDraggable";
import InitDraggable from "./components/Draggable/InitDraggable";
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

function downloadURI(uri, name) {
  var link = document.createElement("a");
  link.download = name;
  link.href = uri;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

function App() {
  const [pdf, setPdf] = useState(null);
  const [autoDate, setAutoDate] = useState(true);
  const [signatureURL, setSignatureURL] = useState(null);
  const [stamp, setStamp] = useState({
    stampUrl: null,
    stampImage: null,
  });
  const { stampUrl, stampImage } = stamp;
  const [position, setPosition] = useState(null);
  const [placeHolder, setPlaceHolder] = useState("");
  const [checkbox, setCheckbox] = useState(false);
  const [signatureDialogVisible, setSignatureDialogVisible] = useState(false);
  const [stampDialogVisible, setStampDialogVisible] = useState(false);
  const [initDialogVisible, setInitDialogVisible] = useState(false);
  const [textInputVisible, setTextInputVisible] = useState(false);
  const [pageNum, setPageNum] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageDetails, setPageDetails] = useState(null);
  const [selectedSig, setSelectedSig] = useState({
    name: "",
    styleOfName: "",
  });
  const { name, styleOfName } = selectedSig;
  const documentRef = useRef(null);

  const handleDraggableSignature = async () => {
    const { originalHeight, originalWidth } = pageDetails;
    const scale = originalWidth / documentRef.current.clientWidth;

    const y =
      documentRef.current.clientHeight -
      (position.y +
        window.pageYOffset -
        position.offsetY +
        64 -
        documentRef.current.offsetTop);
    const x =
      position.x - 160 - position.offsetX - documentRef.current.offsetLeft;

    // new XY in relation to actual document size
    const newY = (y * originalHeight) / documentRef.current.clientHeight;
    const newX = (x * originalWidth) / documentRef.current.clientWidth;

    const pdfDoc = await PDFDocument.load(pdf);
    let imageBytes = "";
    if (stampUrl) {
      imageBytes = await stampUrl.arrayBuffer();
    }

    const pages = pdfDoc.getPages();
    const firstPage = pages[pageNum];
    const pngImage = await pdfDoc.embedPng(
      stampUrl ? imageBytes : signatureURL
    );
    const pngDims = pngImage.scale(scale * 0.3);

    firstPage.drawImage(pngImage, {
      x: newX,
      y: newY,
      width: pngDims.width,
      height: pngDims.height,
    });

    if (autoDate) {
      firstPage.drawText(
        `${stampUrl ? "Stamped" : "Signed"} ${dayjs().format(
          "M/d/YYYY HH:mm:ss ZZ"
        )}`,
        {
          x: newX,
          y: newY - 10,
          size: 14 * scale,
        }
      );
    }

    const pdfBytes = await pdfDoc.save();
    const blob = new Blob([new Uint8Array(pdfBytes)]);

    const URL = await blobToURL(blob);
    setPdf(URL);
    setPosition(null);
    setStamp({ stampImage: null, stampUrl: null });
    setSignatureURL(null);
  };

  const handleDraggableText = async (text) => {
    const { originalHeight, originalWidth } = pageDetails;
    const scale = originalWidth / documentRef.current.clientWidth;
    const y =
      documentRef.current.clientHeight -
      (position.y +
        window.pageYOffset +
        (name ? 73 : 23) * scale -
        position.offsetY -
        documentRef.current.offsetTop);
    const x =
      position.x -
      (name ? 16 : 166) -
      position.offsetX -
      documentRef.current.offsetLeft;

    const newY = (y * originalHeight) / documentRef.current.clientHeight;
    const newX = (x * originalWidth) / documentRef.current.clientWidth;

    const pdfDoc = await PDFDocument.load(pdf);
    pdfDoc.registerFontkit(fontkit);
    const url =
      styleOfName === "dancing-script"
        ? danceScript
        : styleOfName === "pacifico"
        ? pacifico
        : styleOfName === "sacramento"
        ? sacramentoRegular
        : styleOfName === "allura"
        ? alluraRegular
        : styleOfName === "caveat"
        ? caveatRegular
        : styleOfName === "satisfy"
        ? satisfyRegular
        : styleOfName === "parisienne"
        ? parisienneRegular
        : styleOfName === "over-the-rainbow"
        ? overtheRainbow
        : robotRegular;
    let fontBytes = await fetch(url).then((res) => res.arrayBuffer());
    let customFont = await pdfDoc.embedFont(fontBytes);
    const pages = pdfDoc.getPages();
    const firstPage = pages[pageNum];
    firstPage.drawText(name ? name : text, {
      x: newX,
      y: newY,
      size: 20 * scale,
      font: customFont,
    });

    const pdfBytes = await pdfDoc.save();
    const blob = new Blob([new Uint8Array(pdfBytes)]);
    const URL = await blobToURL(blob);
    setPdf(URL);
    setPosition(null);
    setTextInputVisible(false);
    setSelectedSig({
      styleOfName: "",
      name: "",
    });
  };

  const handleDraggableCheckbox = async () => {
    const { originalHeight, originalWidth } = pageDetails;
    const scale = originalWidth / documentRef.current.clientWidth;

    const y =
      documentRef.current.clientHeight -
      (position.y +
        window.pageYOffset -
        position.offsetY +
        48 -
        documentRef.current.offsetTop);
    const x =
      position.x + 1 - position.offsetX - documentRef.current.offsetLeft;

    // new XY in relation to actual document size
    const newY = (y * originalHeight) / documentRef.current.clientHeight;
    const newX = (x * originalWidth) / documentRef.current.clientWidth;

    const pdfDoc = await PDFDocument.load(pdf);
    let imageBytes = "";

    // Replace 'imageURL' with the actual URL of your image in the repository
    const imageURL = imageCheck;

    // Fetch the image from the repository
    const imageResponse = await fetch(imageURL);
    if (imageResponse.ok) {
      imageBytes = await imageResponse.arrayBuffer();
    } else {
      console.error("Failed to fetch image");
      return;
    }

    const pages = pdfDoc.getPages();
    const firstPage = pages[pageNum];
    const pngImage = await pdfDoc.embedPng(imageBytes);
    const pngDims = pngImage.scale(scale * 0.6);

    firstPage.drawImage(pngImage, {
      x: newX,
      y: newY,
      width: pngDims.width,
      height: pngDims.height,
    });

    const pdfBytes = await pdfDoc.save();
    const blob = new Blob([new Uint8Array(pdfBytes)]);

    const URL = await blobToURL(blob);
    setPdf(URL);
    setPosition(null);
    setCheckbox("");
  };
  const handleText = (value) => {
    setTextInputVisible(true);
    setPlaceHolder(value);
    setSignatureDialogVisible(false);
    setInitDialogVisible(false);
    setStampDialogVisible(false);
  };

  const handleCheckbox = () => {
    setCheckbox(true);
  };

  const handleDialog = (value) => {
    if (value === "signature") {
      setSignatureDialogVisible(true);
      setInitDialogVisible(false);
      setStampDialogVisible(false);
    } else if (value === "intial") {
      setInitDialogVisible(true);
      setSignatureDialogVisible(false);
      setStampDialogVisible(false);
    } else if (value == "stamp") {
      setStampDialogVisible(true);
      setInitDialogVisible(false);
      setTextInputVisible(false);
    }
    setTextInputVisible(false);
    document.body.style.overflow = "hidden";
  };
  return (
    <div>
      <Header />
      <div>
        {initDialogVisible && (
          <DefaultSignature
            onClose={() => {
              setInitDialogVisible(false);
              document.body.style.overflow = "unset";
            }}
            onConfirm={(style, name) => {
              setSelectedSig({
                styleOfName: style,
                name: name,
              });
              document.body.style.overflow = "unset";
              setInitDialogVisible(false);
            }}
          />
        )}
        {signatureDialogVisible && (
          <SignatureModal
            autoDate={autoDate}
            setAutoDate={setAutoDate}
            onClose={() => {
              setSignatureDialogVisible(false);
              document.body.style.overflow = "unset";
            }}
            onConfirm={(url) => {
              setSignatureURL(url);
              document.body.style.overflow = "unset";
              setSignatureDialogVisible(false);
            }}
          />
        )}
        {stampDialogVisible && (
          <StampModal
            onClose={() => {
              setStampDialogVisible(false);
              document.body.style.overflow = "unset";
            }}
            autoDate={autoDate}
            setAutoDate={setAutoDate}
            onConfirm={(url, preview) => {
              setStamp({ stampUrl: url, stampImage: preview });
              document.body.style.overflow = "unset";
              setStampDialogVisible(false);
            }}
          />
        )}

        {!pdf ? (
          <Drop
            onLoaded={async (files) => {
              const URL = await blobToURL(files[0]);
              setPdf(URL);
            }}
          />
        ) : null}
        {pdf ? (
          <div style={{ display: "flex" }}>
            <div
              className="controls"
              style={{
                pointerEvents:
                  (initDialogVisible ||
                    signatureDialogVisible ||
                    stampDialogVisible) &&
                  "none",
              }}
            >
              {!signatureURL ? (
                <NavTab
                  title={"Signature"}
                  onClick={() => handleDialog("signature")}
                  icon={<SignatureIcon />}
                />
              ) : null}
              <NavTab
                title={"Intial"}
                icon={<IntialIcon />}
                onClick={() => handleDialog("intial")}
              />
              <NavTab
                title={"Stamp"}
                onClick={() => handleDialog("stamp")}
                icon={<StampIcon />}
              />
              <NavTab
                title={"Date Signed"}
                onClick={() => setTextInputVisible("date")}
                icon={<DateIcon />}
              />
              <NavTab
                title={"Name"}
                onClick={() => handleText("Name")}
                icon={<NameIcon />}
              />
              <NavTab
                title={"First Name"}
                icon={<FirstLastNameIcon />}
                onClick={() => handleText("First Name")}
              />
              <NavTab
                title={"Last Name"}
                icon={<FirstLastNameIcon />}
                onClick={() => handleText("Last Name")}
              />
              <NavTab
                title={"Email Address"}
                onClick={() => handleText("Email Address")}
                icon={<EmailIcon />}
              />
              <NavTab
                title={"Company"}
                onClick={() => handleText("Company")}
                icon={<CompanyIcon />}
              />
              <NavTab
                title={"Title"}
                onClick={() => handleText("Title")}
                icon={<TitleIcon />}
              />

              <NavTab
                title={"Text"}
                onClick={() => handleText("Text")}
                icon={<TextIcon />}
              />
              <NavTab
                title={"Checkbox"}
                onClick={() => handleCheckbox("checkbox")}
                icon={<CheckboxIcon />}
              />

              <NavTab
                title={"Reset"}
                onClick={() => {
                  setTextInputVisible(false);
                  setSignatureDialogVisible(false);
                  setSignatureURL(null);
                  setPdf(null);
                  setTotalPages(0);
                  setPageNum(0);
                  setPageDetails(null);
                }}
                icon={<ResetIcon />}
              />
              {pdf ? (
                <NavTab
                  inverted={true}
                  title={"Download"}
                  onClick={() => {
                    downloadURI(pdf, "file.pdf");
                  }}
                  icon={<DownloadIcon />}
                />
              ) : null}
            </div>
            <div>
              <div ref={documentRef} className="documentBlock">
                {textInputVisible ? (
                  <TextDraggable
                    initialText={
                      textInputVisible === "date"
                        ? dayjs().format("M/d/YYYY")
                        : null
                    }
                    onCancel={() => setTextInputVisible(false)}
                    onEnd={setPosition}
                    placeholder={placeHolder}
                    onSet={(text) =>
                      text === "checkbox"
                        ? handleDraggableText()
                        : handleDraggableText(text)
                    }
                  />
                ) : null}
                {signatureURL ? (
                  <ImageDraggable
                    url={signatureURL}
                    onCancel={() => {
                      setSignatureURL(null);
                    }}
                    onSet={() => handleDraggableSignature()}
                    onEnd={setPosition}
                  />
                ) : null}
                {checkbox ? (
                  <CheckboxDraggable
                    onCancel={() => {
                      setCheckbox(null);
                    }}
                    onSet={() => handleDraggableCheckbox()}
                    onEnd={setPosition}
                  />
                ) : null}
                {name ? (
                  <InitDraggable
                    onCancel={() => {
                      setSelectedSig(null);
                    }}
                    name={name}
                    styleOfName={styleOfName}
                    onSet={() => handleDraggableText()}
                    onEnd={setPosition}
                  />
                ) : null}
                {stampImage ? (
                  <ImageDraggable
                    url={stampImage}
                    onCancel={() => {
                      setStamp({ stampUrl: null, stampImage: null });
                    }}
                    onSet={() => handleDraggableSignature()}
                    onEnd={setPosition}
                  />
                ) : null}
                <div>
                  <Document
                    file={pdf}
                    onLoadSuccess={(data) => {
                      setTotalPages(data.numPages);
                    }}
                  >
                    <Page
                      pageNumber={pageNum + 1}
                      width={800}
                      height={1200}
                      renderAnnotationLayer={false}
                      renderTextLayer={false}
                      onLoadSuccess={(data) => {
                        setPageDetails(data);
                      }}
                    />
                  </Document>
                </div>
              </div>
              <Pagination
                pageNum={pageNum}
                setPageNum={setPageNum}
                totalPages={totalPages}
              />
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default App;
