import Draggable from "react-draggable";
import { FaCheck, FaTimes } from "react-icons/fa";
import {
  errorColor,
  goodColor,
  primary45,
} from "../../utils/colors";

export default function ImageDraggable({ url, onEnd, onSet, onCancel }) {
  const styles = {
    container: {
      position: "absolute",
      zIndex: 100000,
      marginTop: "100px",
      border: `2px solid ${primary45}`,
    },
    controls: {
      position: "absolute",
      right: 0,
      display: "inline-block",
      backgroundColor: primary45,
      // borderRadius: 4,
    },
    smallButton: {
      display: "inline-block",
      cursor: "pointer",
      padding: 5,
    },
  };
  return (
    <Draggable onStop={onEnd}>
      <div style={styles.container}>
        <div style={styles.controls}>
          <div style={styles.smallButton} onClick={url && onSet}>
            <FaCheck color={goodColor} />
          </div>
          <div style={styles.smallButton} onClick={onCancel}>
            <FaTimes color={errorColor} />
          </div>
        </div>
        <img src={url} alt="img" width={200} style={styles.img} draggable={false} />
      </div>
    </Draggable>
  );
}
