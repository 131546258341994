import React, { useState } from "react";
import { ConfirmOrCancel } from "../Buttons/ConfirmOrCancel";
import { primary45 } from "../../utils/colors";
import { ContainerModal } from "./ContainerModal";

export function StampModal({ onConfirm, onClose, autoDate, setAutoDate }) {
  const [image, setImage] = useState(null);
  const [file, setFile] = useState(null);
  const styles = {
    stampContainer: {
      display: "flex",
      justifyContent: "center",
    },
    stampBlock: {
      display: "inline-block",
      // border: `1px solid ${primary45}`,
    },
    inputField: {
      marginBottom: "10px",
    },
    inputPreviewBlock: {
      width: 600,
      height: 200,
      border: `1px solid ${primary45}`,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    instructions: {
      display: "flex",
      justifyContent: "space-between",
      textAlign: "center",
      color: primary45,
      marginTop: 8,
      width: 600,
      alignSelf: "center",
    },
    instructionsContainer: {
      display: "flex",
      justifyContent: "center",
    },
  };

  const handleChange = (e) => {
    const file = e.target.files[0];
    const objectUrl = URL.createObjectURL(file);
    setFile(file);
    setImage(objectUrl);
  };

  return (
    <ContainerModal
      isVisible={true}
      title="Add Stamp"
      onClose={onClose}
      body={
        <div style={styles.container}>
          <div style={styles.stampContainer}>
            <div style={styles.stampBlock}>
              <label for="file-upload" class="custom-file-upload">
                Upload Image
              </label>
              <input
                onChange={handleChange}
                name="Upload Image"
                id="file-upload"
                style={styles?.inputField}
                accept=".png"
                type="file"
              />
              <div style={styles.inputPreviewBlock}>
                {image ? (
                  <img src={image} alt="img-preview" width={400} height={150} />
                ) : (
                  <h1> Image Here</h1>
                )}
              </div>
            </div>
          </div>
          <div style={styles.instructionsContainer}>
            <div style={styles.instructions}>
              <div>
                Auto date/time{" "}
                <input
                  type={"checkbox"}
                  checked={autoDate}
                  onChange={(e) => setAutoDate(e.target.checked)}
                />
              </div>
              <div>Upload stamp above</div>
            </div>
          </div>

          <ConfirmOrCancel
            onCancel={onClose}
            disabled={!file}
            onConfirm={() => {
              onConfirm(file, image);
            }}
          />
        </div>
      }
    />
  );
}
