export const primary = '#2b6284';
export const primary2 = '#ecf4f9';
export const primary3 = '#9fc7e0';
export const primary35 = '#97bace';
export const primary4 = 'black';
export const primary45 = 'black';
export const primary46 = '#6778cb';

export const primary15 = 'black';

export const primary5 = '#3881ad';
export const primary6 = '#132b3a';

// export const primary = '#666';
// export const primary2 = '#EEE';
// export const primary3 = '#CCC';
// export const primary4 = '#AAA';
// export const primary5 = '#888';
// export const primary6 = '#333';

export const primary16 = 'hsl(208 100% 96% / 1)';

export const errorColor = '#ef6565';
export const lightErrorColor = '#ef9c9c';
export const goodColor = '#53c171';

export const cleanBorder = '1px solid black';
export const lightBorder = 'hsl(203 51% 80% / 1)';
