import Draggable from "react-draggable";
import { FaCheck, FaTimes } from "react-icons/fa";
import { errorColor, goodColor, primary45 } from "../../utils/colors";
import "@fontsource/dancing-script"; // Dancing Script
import "@fontsource/pacifico"; // Pacifico
import "@fontsource/sacramento"; // Sacramento
import "@fontsource/allura"; // Allura
import "@fontsource/caveat"; // Caveat
import "@fontsource/satisfy"; // Satisfy
import "@fontsource/parisienne"; // Parisienne
import "@fontsource/over-the-rainbow"; // Over the Rainbow
export default function InitDraggable({ name, styleOfName, onEnd, onSet, onCancel }) {
  const styles = {
    container: {
      position: "absolute",
      zIndex: 100000,
      marginTop: "100px",
      border: `2px solid ${primary45}`,
    },
    controls: {
      position: "absolute",
      right: 0,
      display: "inline-block",
      backgroundColor: primary45,
      // borderRadius: 4,
    },
    smallButton: {
      display: "inline-block",
      cursor: "pointer",
      padding: 5,
    },
    signStyle: {
        width: 100,
        height: '100px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
  };
  {console.log(name)}
  return (
    <Draggable onStop={onEnd}>
      <div style={styles.container}>
        <div style={styles.controls}>
          <div style={styles.smallButton} onClick={onSet}>
            <FaCheck color={goodColor} />
          </div>
          <div style={styles.smallButton} onClick={onCancel}>
            <FaTimes color={errorColor} />
          </div>
        </div>
        <div style={styles?.signStyle} className={`${styleOfName}`}>
            {name}
        </div>
      </div>
    </Draggable>
  );
}
